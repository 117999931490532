export default {
  "atlantis-mobile-app": () => import("/home/bun/app/layouts/AtlantisMobileApp.vue").then(m => m.default || m),
  atlantisbahamas: () => import("/home/bun/app/layouts/Atlantisbahamas.vue").then(m => m.default || m),
  barbie: () => import("/home/bun/app/layouts/Barbie.vue").then(m => m.default || m),
  empty: () => import("/home/bun/app/layouts/Empty.vue").then(m => m.default || m),
  "music-making-waves": () => import("/home/bun/app/layouts/MusicMakingWaves.vue").then(m => m.default || m),
  npiwff: () => import("/home/bun/app/layouts/Npiwff.vue").then(m => m.default || m),
  paranza: () => import("/home/bun/app/layouts/Paranza.vue").then(m => m.default || m),
  reef: () => import("/home/bun/app/layouts/Reef.vue").then(m => m.default || m),
  summer: () => import("/home/bun/app/layouts/Summer.vue").then(m => m.default || m),
  summer2024: () => import("/home/bun/app/layouts/Summer2024.vue").then(m => m.default || m)
}
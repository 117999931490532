<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'
import IconsPlusButton from '@/components/icons/IconsPlusButton.vue'
import { useAtlantisLink } from '@/composables/useAtlantisLink'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  description: 'Dining Feature Card',
  fields: {
    image: { label: 'Image', type: 'media', breakpoints: { default: { width: 1200, height: 800 } } },
    imageAnimatedGif: { label: 'Is this an animated image?', type: 'checkbox' },
    size: { type: 'select', label: 'Size', allowNull: false, options: { full: 'Full', large: 'Large', medium: 'Medium' } },
    imageRight: { type: 'checkbox', label: 'Image Right?' },
    showBlueCard: { type: 'checkbox', label: 'Show Blue Card at Top?' },
    blueCardTop: { label: 'Blue Card Top', type: 'text' },
    blueCardBottom: { label: 'Blue Card Bottom', type: 'text' },
    subtitle: { label: 'Subtitle', type: 'text' },
    title: { label: 'Title', type: 'text' },
    titleUnderline: { label: 'Title has underline?', type: 'checkbox', default: true },
    textAlignment: { label: 'Text Alignment', type: 'select', options: { left: 'Left', center: 'Center', right: 'Right' } },
    copy: { label: 'Copy', type: 'wysiwyg' },
    link: { label: 'Button Link', type: 'link', enabled: true },
    linkMode: { type: 'select', label: 'Link Mode', allowNull: false, options: { linedraw: 'Line Draw', ghost: 'Ghost' } },
    link2: { label: 'Button Link 2', type: 'link', enabled: true },
    linkMode2: { type: 'select', label: 'Link Mode 2', allowNull: false, options: { linedraw: 'Line Draw', ghost: 'Ghost' } },
    showBookNow: { type: 'checkbox', label: 'Show Book Now Button?' },
    overrideBookNowLabel: { type: 'text', label: 'Override Book Now Label', enabled: false },
    cobrand: { type: 'text', label: 'Cobrand' },
    secondaryLink: { label: 'Very Bottom Link', type: 'link' },
    largeMargin: { type: 'checkbox', label: 'Large Margin?', default: true },
    galleryImage1: { type: 'media', label: 'Gallery Image 1', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } } },
    galleryImage2: { type: 'media', label: 'Gallery Image 2', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } } },
    galleryImage3: { type: 'media', label: 'Gallery Image 3', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } } },
    galleryImage4: { type: 'media', label: 'Gallery Image 4', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } } },
    galleryImage5: { type: 'media', label: 'Gallery Image 5', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } } },
  },
  name: { label: 'Dining Feature', group: 'Glue' },
  templates: [{
    label: 'Dining Feature',
    fields: {
      copy: { value: '<p>Throughout the year, there are countless ways to celebrate holidays and special occasions at Atlantis.<br><br>Leave the planning to us while you enjoy the unique events, festive celebrations, and countless ways to indulge in the many gifts of The Bahamas.<\/p>' },
      image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/aquaventure\/atlantis-baths-collonade-pool-family-splashing.jpeg' },
      imageRight: { value: true },
      link: { value: { text: 'join the fun', target: '_self', relationship: null, href: '\/new-page' } },
      linkMode: { value: 'ghost' },
      linkMode2: { value: 'linedraw' },
      size: { value: 'large' },
      subtitle: { value: 'at Atlantis' },
      textAlignment: { value: 'center' },
      title: { value: 'spring into paradise' },
    },
  }],
})

const mousingOver = ref(true)
const showGallery = ref(false)

const { $voix }: any = useNuxtApp()

const cardSize = computed(() => {
  if ($voix.breakpoint.value !== 'default' && $voix.breakpoint.value !== 'sm' && $voix.breakpoint.value !== 'md') {
    if (props.fields.size.value === 'full')
      return { 'max-width': '100%' }

    if (props.fields.size.value === 'large')
      return { 'max-width': '80%' }

    return { 'max-width': '70%' }
  }
  return ''
})

const isGallery = computed(() => {
  return (
    props.fields.galleryImage1.value?.[0]?.url
    || props.fields.galleryImage2.value?.[0]?.url
    || props.fields.galleryImage3.value?.[0]?.url
    || props.fields.galleryImage4.value?.[0]?.url
    || props.fields.galleryImage5.value?.[0]?.url
  )
})

const gallery = computed(() => {
  const gallery = []
  if (props.fields.galleryImage1.value?.[0]?.url)
    gallery.push(props.fields.galleryImage1.media)

  if (props.fields.galleryImage2.value?.[0]?.url)
    gallery.push(props.fields.galleryImage2.media)

  if (props.fields.galleryImage3.value?.[0]?.url)
    gallery.push(props.fields.galleryImage3.media)

  if (props.fields.galleryImage4.value?.[0]?.url)
    gallery.push(props.fields.galleryImage4.media)

  if (props.fields.galleryImage5.value?.[0]?.url)
    gallery.push(props.fields.galleryImage5.media)

  return gallery
})

const { onLinkCLick } = useAtlantisLink()
</script>

<template>
  <div class="flex flex-col items-center px-8 md:px-0">
    <div
      class="container relative"
      :style="cardSize"
      @mouseenter="mousingOver = true"
      @mouseleave="mousingOver = false"
    >
      <div
        v-if="fields.showBlueCard.value"
        class="absolute top-0 left-0 -mt-16 -ml-6 md:-ml-4 p-6 md:p-8 w-32 md:w-40 h-46 bg-glueblue-700 text-white hotel-theme-bg hotel-theme-fg z-10 text-center leading-relaxed flex flex-col items-center"
      >
        <div class="uppercase font-light font-sans tracking-widest">
          {{ fields.blueCardTop.value }}
        </div>
        <div class="border-r border-white h-8 opacity-50 my-1" />
        <div class="font-light font-serif italic text-xl tracking-widest">
          {{ fields.blueCardBottom.value }}
        </div>
      </div>
      <div
        class="relative z-0 flex flex-col md:flex-row"
        :class="{ 'md:flex-row-reverse': fields.imageRight.value }"
      >
        <div class="flex-grow overflow-hidden relative flex">
          <VoixMedia v-slot="slotProps" :field="fields.image" background>
            <div
              class="bg-cover bg-center min-h-[400px] transform duration-300 w-full h-full"
              :class="{ 'scale-105': mousingOver }"
              :style="{ backgroundImage: `url(${slotProps.image})` }"
            >
              <button
                v-if="isGallery"
                class="text-white absolute bottom-0 right-0 mr-8 mb-8"
                @click="showGallery = true"
              >
                <IconsPlusButton style="width: 22px; height: 22px" />
              </button>

              <GlueGalleriesModalGallery
                v-if="isGallery"
                :show="showGallery"
                :gallery="gallery[0]"
                small-key="small"
                large-key="large"
                @close="showGallery = false"
              />
            </div>
          </VoixMedia>
        </div>
        <div
          class="md:w-[25rem] bg-white px-8 flex flex-col justify-between"
          :class="{
            'md:w-96': fields.size.value === 'medium',
            'md:w-110': fields.size.value === 'full' || fields.size.value === 'large',
            'lg:py-24': fields.largeMargin.value,
            'lg:py-10': !fields.largeMargin.value,
            'text-left': fields.textAlignment.value === 'left',
            'text-center': fields.textAlignment.value === 'center',
            'text-right': fields.textAlignment.value === 'right',
          }"
        >
          <div class="py-8">
            <div
              class="w-54 mb-6"
              :class="{
                'border-b border-gray-300': fields.titleUnderline.value,
              }"
            >
              <div
                class="uppercase font-sans font-light leading-snug text-gray-800 text-3xl"
              >
                {{ fields.title.value }}
              </div>
              <div class="font-serif font-light text-red-500 italic mb-4 text-2xl tracking-wider">
                {{ fields.subtitle.value }}
              </div>
            </div>
            <div
              class="text-gray-500 font-sans2 text-15 leading-relaxed wysiwyg"
              v-html="fields.copy.value"
            />
          </div>

          <div class="flex flex-col space-y-4 mt-8 pb-8">
            <div
              v-if="fields.link.enabled"
              class="flex space-x-6"
              :class="{ 'justify-end': fields.textAlignment.value === 'right' }"
            >
              <a
                v-if="fields.imageAnimatedGif.value"
                href="#"
                class="special-learnmore uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border pb-1"
                :class="{ 'w-full': fields.textAlignment.value === 'center' }"
              >
                {{ fields.link.value.text }}
              </a>
              <a
                v-if="fields.link.value?.href && fields.linkMode.value === 'linedraw'"
                :href="fields.link.value?.href"
                class="special-learnmore uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border pb-1"
                :class="{ 'w-full': fields.textAlignment.value === 'center' }"
                @click="onLinkCLick(fields.link.value?.href)"
              >
                {{ fields.link.value.text }}
              </a>
              <a
                v-if="fields.link.value?.href && fields.linkMode.value === 'ghost'"
                :href="fields.link.value?.href"
                class="btn-ghost uppercase text-2xs text-gray-700 px-8 font-sans border-gray-500 hover:hotel-theme-bg hover:hotel-theme-fg inline-block text-center py-4 leading-none btn-glue-active tracking-wider"
                :class="{ 'w-full': fields.textAlignment.value === 'center' }"
                @click="onLinkCLick(fields.link.value?.href)"
              >
                {{ fields.link.value.text }}
              </a>
            </div>
            <div
              v-if="fields.link2.enabled"
              class="flex space-x-6"
              :class="{ 'justify-end': fields.textAlignment.value === 'right' }"
            >
              <a
                v-if="fields.imageAnimatedGif.value"
                href="#"
                class="special-learnmore uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border pb-1"
                :class="{ 'w-full': fields.textAlignment.value === 'center' }"
              >
                {{ fields.link2.value.text }}
              </a>
              <a
                v-if="fields.link2.value?.href && fields.linkMode2.value === 'linedraw'"
                :href="fields.link2.value?.href"
                class="special-learnmore uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border pb-1"
                :class="{ 'w-full': fields.textAlignment.value === 'center' }"
                @click="onLinkCLick(fields.link2.value?.href)"
              >
                {{ fields.link2.value.text }}
              </a>
              <a
                v-if="fields.link2.value?.href && fields.linkMode2.value === 'ghost'"
                :href="fields.link2.value?.href"
                class="btn-ghost uppercase text-2xs text-gray-700 px-8 font-sans border-gray-500 inline-block text-center py-4 leading-none btn-glue-active tracking-wider"
                :class="{ 'w-full': fields.textAlignment.value === 'center' }"
                @click="onLinkCLick(fields.link2.value?.href)"
              >
                {{ fields.link2.value.text }}
              </a>
            </div>
          </div>
          <BookingBookNow
            v-if="fields.showBookNow.value"
            :cobrand="fields.cobrand.value"
            location="booking-card"
            disableable
          >
            <button
              class="mt-6 tracking-wider w-full p-3 text-xs bg-white text-black border border-gray-900 hover:hotel-theme-bg hover:hotel-theme-fg"
            >
              {{ fields.overrideBookNowLabel.enabled ? fields.overrideBookNowLabel.value : 'BookNow' }}
            </button>
          </BookingBookNow>

          <a
            v-if="fields.secondaryLink.value?.href"
            :href="fields.secondaryLink.value?.href"
            class="pt-4 special-learnmore uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hotel-theme-border hotel-theme-fg-inverse hover-draw-border pb-1"
            :class="{ 'w-full': fields.textAlignment.value === 'center' }"
            @click="onLinkCLick(fields.secondaryLink.value?.href)"
          >
            {{ fields.secondaryLink.value.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
